<template>
  <section class="content">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">NAMA KEGIATAN</label>
            <input
              id="nama_kegiatan"
              class="form-control"
              v-model="form.nama_kegiatan"
              type="text"
              name="nama_kegiatan"
              readonly
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="control-label">PROPOSAL TANGGAL MULAI</label>
            <datepicker
              placeholder="Tanggal Mulai"
              v-model="form.proposal_tanggal_mulai"
              readonly
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="control-label">PROPOSAL TANGGAL SELESAI</label>
            <datepicker
              placeholder="Tanggal Selesai"
              v-model="form.proposal_tanggal_selesai"
              readonly
            />
          </div>
          <div class="col-md-6 form-group">
            <label class="control-label">DESKRIPSI KEGIATAN</label>
            <input
              id="deskripsi_kegiatan"
              class="form-control"
              v-model="form.deskripsi_kegiatan"
              type="text"
              name="deskripsi_kegiatan"
              readonly
            />
          </div>
        </div>
        <div class="p-1"></div>
        <table class="table table-hover" ref="tblproposal">
          <thead>
            <tr>
              <th>TANGGAL MULAI</th>
              <th>TANGGAL SELESAI</th>
              <th>PROPOSAL ANGGARAN</th>
              <th>DOKUMEN</th>
            </tr>
          </thead>
          <tbody @click="handleClick"></tbody>
        </table>
        <div class="p-1"></div>
        <table class="table table-hover" ref="tblpj">
          <thead>
            <tr>
              <th>LPJ ANGGARAN</th>
              <th>LPJ LAPORAN</th>
              <th>DOKUMEN</th>
            </tr>
          </thead>
          <tbody @click="handleClick"></tbody>
        </table>

        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitFormProposal">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <input type="hidden" name="row_id" value="" v-model="formProposal.id"/>
                            <input type="hidden" name="kegiatan_id" value="" v-model="formProposal.pr_kegiatan_id"/>
                            <input type="hidden" name="img_code" value="" v-model="formProposal.code" id="img_code" />
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" :key="error">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                              <label class="control-label">PROPOSAL TANGGAL MULAI</label>
                              <datepicker
                                placeholder="Tanggal Mulai"
                                v-model="formProposal.tanggal_mulai"
                              />
                            </div>
                            <div class="col-md-6 form-group">
                              <label class="control-label">PROPOSAL TANGGAL SELESAI</label>
                              <datepicker
                                placeholder="Tanggal Selesai"
                                v-model="formProposal.tanggal_selesai"
                              />
                            </div>
                            <div class="col-md-6 form-group">
                              <label class="control-label">PROPOSAL ANGGARAN</label>
                              <!-- <input
                                id="proposal_anggaran"
                                class="form-control"
                                v-model="formProposal.anggaran"
                                type="number"
                                name="anggaran"
                              /> -->
                              <currency-input id="proposal_anggaran" class="form-control" currency="IDR" v-model="formProposal.anggaran" :precision="0" name="proposal_anggaran" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFile">Dokumen</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadProposal(this)">
                                    <label class="custom-file-label" for="inputFile" ref="textProposal">Choose file</label>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                              <label class="control-label">Keterangan</label>
                              <textarea
                                class="form-control"
                                id="deskripsi_kegiatan"
                                rows="3"
                                v-model="formProposal.keterangan"
                                placeholder="Tuliskan keterangan"
                              ></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" ref="formDialogLPJ" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitFormLPJ">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <input type="hidden" name="row_id" value="" v-model="formLPJ.id"/>
                            <input type="hidden" name="kegiatan_id" value="" v-model="formLPJ.pr_kegiatan_id"/>
                            <input type="hidden" name="img_code" value="" v-model="formLPJ.code" id="img_code" />
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" :key="error">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                              <label class="control-label">PROPOSAL ANGGARAN</label>
                              <!-- <input
                                id="proposal_anggaran"
                                class="form-control"
                                v-model="formLPJ.anggaran"
                                type="number"
                                name="anggaran"
                              /> -->
                              <currency-input id="proposal_anggaran" class="form-control" currency="IDR" v-model="formLPJ.anggaran" :precision="0" name="proposal_anggaran" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFileLPJ">Dokumen</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfileLPJ" id="inputFileLPJ"  name="file" @change="uploadLPJ(this)">
                                    <label class="custom-file-label" ref="textProposalLPJ" for="inputFileLPJ">Choose file</label>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                              <label class="control-label">Keterangan</label>
                              <textarea
                                class="form-control"
                                id="deskripsi_kegiatan"
                                rows="3"
                                v-model="formLPJ.keterangan"
                                placeholder="Tuliskan keterangan"
                              ></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <router-link
          :to="{ path: '/proposal-lpj-kegiatan' }"
          class="btn btn-secondary"
        >
          Kembali
        </router-link>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css'
import $ from "jquery";
import datepicker from "@/components/Datepicker";
import { CurrencyInput } from "vue-currency-input";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Detail",
      id: 0,
      form: {
        nama_kegiatan: "",
        proposal_tanggal_mulai: "",
        proposal_tanggal_selesai: "",
        deskripsi_kegiatan: "",
      },
      formProposal: {
        id: '',
        pr_kegiatan_id: '',
        code: '',
        tipe: 0,
        tanggal_mulai: '',
        tanggal_selesai: '',
        anggaran: '',
        keterangan: '',
      },
      formLPJ: {
        id: '',
        pr_kegiatan_id: '',
        code: '',
        tipe: 1,
        anggaran: '',
        keterangan: '',
      },
      form_data: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    if (this.$route.params.id) {
      this.loadDetail(this.$route.params.id);
      this.id = this.$route.params.id
    } else {
    }
  },
  methods: {
    handleClick(e) {
      //   if (e.target.matches(".link-role")) {
      //     this.$router.push({ path: "/permit/" + e.target.dataset.id });
      //     return false;
      //   }
      if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
          this.download(e.srcElement.getAttribute('data-href'));
      }
    },
    loadDetail(id) {
      authFetch("/pr/proposal_lpj_kegiatan/" + id).then((res) => {
        res.json().then((json) => {
          this.form = json;
          this.formProposal.pr_kegiatan_id = json.id
          this.formLPJ.pr_kegiatan_id = json.id
        });
      });
    },
    submitFormProposal: function(ev) {
        const e = this.$refs;
        var self = this;
        var file_data = $('#inputFile').prop('files');
        if (file_data.length == 0) {
            var data = Object.keys(this.formProposal).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.formProposal[key])).join('&')
              var urlSubmit = '/pr/proposal_lpj_kegiatan/add_document';
              if (this.method == 'PUT') urlSubmit = '/pr/proposal_lpj_kegiatan/add_document/' + this.formProposal.id;
              authFetch(urlSubmit, {
                      method: this.method,
                      body: data,
                  })
                  .then(res => {
                      if (res.status === 201) {

                      } else if (res.status === 400) {}
                      return res.json();
                  })
                  .then(js => {

                      this.errors = [];
                      if (!js.success) {
                              for (var key in js) {
                                  if (js.hasOwnProperty(key)) {
                                      this.errors.push(js[key])
                                  }
                              }
                              Swal.fire({
                                  icon: 'info',
                                  title: 'Informasi',
                                  text: 'Data tidak tersimpan!'
                              })

                              return;
                          }
                      this.table.api().ajax.reload();
                      $(e.formDialog).modal('hide');
                      Swal.fire({
                          icon: 'success',
                          title: 'Informasi',
                          text: 'Data tersimpan!'
                      })
                  });
        } else {
            this.doUpload().then((res) => {
            if (res.status === 201) {

            } else if (res.status === 400) {}

            return res.json();
            
        }).then(js => {
            this.errors = [];
            if (!js.status) {
                for (var key in js.details) {
                    if (js.details.hasOwnProperty(key)) {
                        this.errors.push(js.details[key])
                    }
                }

                return;
            } else {
                if (js.message[0]) {
                    self.formProposal.code = js.message[0].code;
                }

                var data = Object.keys(this.formProposal).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.formProposal[key])).join('&')
                var urlSubmit = '/pr/proposal_lpj_kegiatan/add_document';
                if (this.method == 'PUT') urlSubmit = '/pr/proposal_lpj_kegiatan/add_document/' + this.formProposal.id;
                authFetch(urlSubmit, {
                        method: this.method,
                        body: data,
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {

                        this.errors = [];
                        if (!js.success) {
                                for (var key in js) {
                                    if (js.hasOwnProperty(key)) {
                                        this.errors.push(js[key])
                                    }
                                }
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Informasi',
                                    text: 'Data tidak tersimpan!'
                                })

                                return;
                            }
                        this.table.api().ajax.reload();
                        $(e.formDialog).modal('hide');
                        Swal.fire({
                            icon: 'success',
                            title: 'Informasi',
                            text: 'Data tersimpan!'
                        })
                    });
            }
        });
        }

        ev.preventDefault();
    },
    submitFormLPJ: function(ev) {
        const e = this.$refs;
        var self = this;
        var file_data = $('#inputFileLPJ').prop('files');
        if (file_data.length == 0) {
            var data = Object.keys(this.formLPJ).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.formLPJ[key])).join('&')
              var urlSubmit = '/pr/proposal_lpj_kegiatan/add_document';
              if (this.method == 'PUT') urlSubmit = '/pr/proposal_lpj_kegiatan/add_document/' + this.formLPJ.id;
              authFetch(urlSubmit, {
                      method: this.method,
                      body: data,
                  })
                  .then(res => {
                      if (res.status === 201) {

                      } else if (res.status === 400) {}
                      return res.json();
                  })
                  .then(js => {

                      this.errors = [];
                      if (!js.success) {
                              for (var key in js) {
                                  if (js.hasOwnProperty(key)) {
                                      this.errors.push(js[key])
                                  }
                              }
                              Swal.fire({
                                  icon: 'info',
                                  title: 'Informasi',
                                  text: 'Data tidak tersimpan!'
                              })

                              return;
                          }
                      this.table.api().ajax.reload();
                      $(e.formDialogLPJ).modal('hide');
                      Swal.fire({
                          icon: 'success',
                          title: 'Informasi',
                          text: 'Data tersimpan!'
                      })
                  });
        } else {
            this.doUpload().then((res) => {
            if (res.status === 201) {

            } else if (res.status === 400) {}

            return res.json();
            
        }).then(js => {
            this.errors = [];
            if (!js.status) {
                for (var key in js.details) {
                    if (js.details.hasOwnProperty(key)) {
                        this.errors.push(js.details[key])
                    }
                }

                return;
            } else {
                if (js.message[0]) {
                    self.formLPJ.code = js.message[0].code;
                }

                var data = Object.keys(this.formLPJ).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.formLPJ[key])).join('&')
                var urlSubmit = '/pr/proposal_lpj_kegiatan/add_document';
                if (this.method == 'PUT') urlSubmit = '/pr/proposal_lpj_kegiatan/add_document/' + this.formLPJ.id;
                authFetch(urlSubmit, {
                        method: this.method,
                        body: data,
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {

                        this.errors = [];
                        if (!js.success) {
                                for (var key in js) {
                                    if (js.hasOwnProperty(key)) {
                                        this.errors.push(js[key])
                                    }
                                }
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Informasi',
                                    text: 'Data tidak tersimpan!'
                                })

                                return;
                            }
                        this.tbl.api().ajax.reload();
                        $(e.formDialogLPJ).modal('hide');
                        Swal.fire({
                            icon: 'success',
                            title: 'Informasi',
                            text: 'Data tersimpan!'
                        })
                    });
            }
        });
        }

        ev.preventDefault();
    },
    uploadProposal : function (file) {
        const e = this.$refs;
        var file_data = $('#inputFile').prop('files');
        var form_data = new FormData();
        var fileInput = $('#inputFile')[0];
        $.each(fileInput.files, function(k,file){
          form_data.append('file', file);
          $(e.textProposal).html(file.name);
        });
        form_data.append('subdir', '/proposal/'+this.id+'/');
        form_data.append('subdir', '/proposal/'+this.id+'/');
        this.form_data = form_data
        return false
    },
    uploadLPJ : function (file) {
        const e = this.$refs;
        var file_data = $('#inputFileLPJ').prop('files');
        var form_data = new FormData();
        var fileInput = $('#inputFileLPJ')[0];
        $.each(fileInput.files, function(k,file){
          form_data.append('file', file);
          $(e.textProposalLPJ).html(file.name);
        });
        form_data.append('subdir', '/lpj/'+this.id+'/');
        form_data.append('subdir', '/lpj/'+this.id+'/');
        this.form_data = form_data
        return false
    },
    doUpload: function() {
        return authFetch('/pr/proposal_lpj_kegiatan/upload_data', {
            method: 'POST',
            body: this.form_data,
            headers: {
            'Content-Type': null,
            }
        })
    },
    download: function(data) {
      window.open(window.$apiUrl+data, "_blank")
    }
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblproposal, {
      title: "Dokumen Proposal",
      roles: this.$route.params.roles,
      ajax: "/pr/proposal_lpj_kegiatan/attachment/"+this.id+'/0',
        columns: [
          { data: "tanggal_mulai" },
          { data: "tanggal_selesai" },
          { data: "anggaran",
          render: function (data, type, row, meta) {
              return "Rp "+formatCurrency(data);
          } },
          { data: "code" },
        ],
      paging: false,
      scrollX: true,
      filterBy: [0, 1],
      rowCallback: function (row, data) {
        if(data.code)$('td:eq(3)', row).html('<a type="button" class="btn btn-primary" data-href="/file/download/'+data.code+'">Download</a>');
      },
      buttonClick: (evt) => {
        if (evt.role == 'create') {
            let id = self.formProposal.pr_kegiatan_id
            self.formProposal = {};
            self.formProposal.tipe = 0;
            self.formProposal.pr_kegiatan_id = id
            self.method = 'POST';
            self.errors = [];
            self.formTitle = 'Tambah Dokumen Proposal';
            $(e.formDialog).modal('show');
            // this.tbl.api().ajax.reload();
        } else if (evt.role == 'update' && evt.data) {
            self.formProposal = evt.data;
            self.formProposal.anggaran = parseFloat(evt.data.anggaran)
            self.method = 'PUT';
            self.errors = [];
            self.formTitle = 'Edit Dokumen Proposal';
            $(e.formDialog).modal('show');
        } else if (evt.role == 'delete' && evt.data) {
            // self.form = evt.data;
            // Swal.fire({
            //     title: "Hapus data?",
            //     icon: "question",
            //     denyButtonText: '<i class="fa fa-times"></i> Hapus',
            //     showCancelButton: true,
            //     showDenyButton: true,
            //     showConfirmButton: false,
            //   }).then((result) => {
            //     if (result.isDenied) {
            //       authFetch('/masterdata/siswa_register/' + evt.data.id, {
            //             method: 'DELETE',
            //             body: 'id=' + evt.data.id
            //         })
            //         .then(res => {
            //             return res.json();
            //         })
            //         .then(js => {
            //             this.table.api().ajax.reload();
            //         });
            //     }
            //   });
        }
      },
    });

    this.tbl = createTable(e.tblpj, {
      title: "Dokumen LPJ",
      roles: this.$route.params.roles,
      ajax: "/pr/proposal_lpj_kegiatan/attachment/"+this.id+'/1',
        columns: [
          { data: "anggaran",
          render: function (data, type, row, meta) {
              return "Rp "+formatCurrency(data);
          } },
          { data: "keterangan" },
          { data: "code" },
        ],
      paging: false,
      scrollX: true,
      filterBy: [0, 1],
      rowCallback: function (row, data) {
        if(data.code)$('td:eq(2)', row).html('<a type="button" class="btn btn-primary" data-href="/file/download/'+data.code+'">Download</a>');
      },
      buttonClick: (evt) => {
        if (evt.role == 'create') {
            let id = self.formLPJ.pr_kegiatan_id
            self.formLPJ = {};
            self.formLPJ.tipe = 1;
            self.formLPJ.pr_kegiatan_id = id
            self.method = 'POST';
            self.errors = [];
            self.formTitle = 'Tambah Dokumen Proposal';
            $(e.formDialogLPJ).modal('show');
            // this.tbl.api().ajax.reload();
        } else if (evt.role == 'update' && evt.data) {
            self.formLPJ = evt.data;
            self.formLPJ.anggaran = parseFloat(evt.data.anggaran)
            self.method = 'PUT';
            self.errors = [];
            self.formTitle = 'Edit Dokumen Proposal';
            $(e.formDialogLPJ).modal('show');
        } else if (evt.role == 'delete' && evt.data) {
        }
      },
    });
  },
  components: {
    datepicker,
    CurrencyInput,
  },
};
</script>
